import select from 'dom-select';

export const GlobalHeader = (el) => {
  const ui = {
    el,
    body: select('body'),
    pageshell: select('.pageshell'),
    searchTrigger: select('.link--global-nav.search'),
    searchInput: select('.global-nav__search .input'),
    globalNav: select('.global-nav'),
    toggles: select.all('.nav-item', document),
  };

  const handleSearchToggle = () => {
    ui.searchTrigger.addEventListener('click', function(e) {
      e.preventDefault();
      ui.globalNav.classList.toggle('search-active');
      ui.searchInput.focus();
    })
  }

  const closeAllToggles = () => {
    ui.toggles.forEach((toggle) => {
    toggle.removeAttribute('aria-expanded');
    });
  }

  const handleBodyScroll = () => {
    let lastScrollTop = 0;

    window.addEventListener("scroll", function(){ 
      let st = window.pageYOffset || document.documentElement.scrollTop; 
      if (st > lastScrollTop){
        // down scroll code
        if (st > 100) {
          ui.pageshell.classList.add('scrolled-down');
          ui.pageshell.classList.remove('scrolled-up');
          closeAllToggles();
        }
      } else {
         // up scroll code
        ui.pageshell.classList.add('scrolled-up');
        ui.pageshell.classList.remove('scrolled-down');

        if (st === 0) {
          ui.pageshell.classList.remove('scrolled-down');
          ui.pageshell.classList.remove('scrolled-up');
        }
      }
      // For Mobile or negative scrolling
      lastScrollTop = st <= 0 ? 0 : st;
    }, 
    false
    );
  }

  const addEvents = () => {
    handleBodyScroll();
    handleSearchToggle();
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default GlobalHeader;
