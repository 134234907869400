import Splide from '@splidejs/splide';
// import { Video } from '@splidejs/splide-extension-video/';

// console.log(Video);

export const Splider = (el) => {
  const addEvents = () => {
    const main = new Splide('#main-carousel', 
      {
        arrows: true,
        pagination: false,
        rewind: true,
        type: 'slide',
      }
    );

    const thumbnails = new Splide('#navigation-carousel', 
      {
        arrows: true,
        gap: '1.5rem',
        fixedWidth : 296,
        fixedHeight: 167,
        isNavigation: true,
        pagination: false,
        rewind: true,
        type: 'slide',
        
        breakpoints: {
          768: {
            gap: '.75rem',
            fixedWidth: 111,
            fixedHeight: 66.5,
          },
        },
      }
    );

    try {
      main.sync(thumbnails);
    } catch {};

    main.mount(Splide.Video);
    thumbnails.mount();
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default Splider;
