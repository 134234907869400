const domReady = (cb = () => {}) => {
  function runOnInit() {
    cb();
  }

  function runOnPageChange() {
    cb();
  }

  document.addEventListener('DOMContentLoaded', () => {
    runOnInit();

    // we only need to run callback() when in Storybook
    if (!window.isTurnStorybook) return;

    const callback = (mutationsList) => {
      for (let i = 0, len = mutationsList.length; i < len; i++) {
        if (mutationsList[i].type === 'childList') {
          runOnPageChange();
          break;
        }
      }
    };

    const observer = new MutationObserver(callback);
    const config = { childList: true, subtree: false };

    observer.observe(document.getElementById('storybook-root'), config);
  }, false);
};

export default domReady;
