import AccordionTabs from 'a11y-accordion-tabs';

export const Accordion = (el) => {
  const addEvents = () => {
    new AccordionTabs(el);
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default Accordion;
